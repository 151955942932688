/* navbar */

.Nav-logo {
  width: 100px;
  height: auto;
  filter: drop-shadow(5px 5px 5px #fff); 
}

.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #12bed3 !important;
}

.navbar {
  background-color: #12bed3;
  color: #fff;
  background: rgb(255,81,0);
  background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);

}

.each-slide-effect>div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}