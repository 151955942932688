.flyers-container {
    padding: 10px;
    text-align: center;
  }
  
  .flyers-heading{
    font-size: 24px;
    text-transform: uppercase;
  }
  
  .flyers-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 16px;
  }
  
  .flyer-card {
    background: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  .flyer-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .flyers-wrapper {
      grid-template-columns: 1fr;
    }

    .flyers-heading{
        font-size: 16px;
      }
  }
  