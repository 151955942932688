/* Basic styles for the header section */
.header--hp01-video-block {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    
  }
  
  .header--hp01-video-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .header--hp01-video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    
  }
  
  .header--hp01-video-desk,
  .header--hp01-video-mob {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }


  
  .header--hp01-video-mob {
    display: none;
  }
  
  .header--hp01-container {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .header--hp01-row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header--hp01-col-12 {
    flex: 1;
    text-align: center;
  }
  
  .header--hp01-video-content {
    color: #fff;
    text-align: center;
    margin-top: 180px;
    margin-left: 100px;
  }
  
  .header--hp01-video-content h1 {
    font-family: "Playfair Display", serif;
    font-size: 100px;
    line-height: 115px;
    font-weight: 400;
    position: relative;
    padding-bottom: 45px;
    margin-bottom: 40px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-shadow: 0px 2px 13px rgba(0, 0, 0, 1);
    font-family: "Cinzel Decorative", serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .header-hr {
    width: 340px;
    height: 4px;
    background-color: #d2ab67;
    display: flex;
    justify-content: flex-start;
  }
  
  .header--hp01-video-content h1:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 340px;
    height: 4px;
    background-color: #d2ab67;
    text-shadow: 0px 2px 13px rgba(0, 0, 0, 1);
  }
  
  .header--hp01-video-content ul {
    list-style-type: none;
    padding: 0;
    text-shadow: 0px 2px 13px rgba(0, 0, 0, 1);
  }
  
  .header--hp01-video-content ul li {
    display: inline-block;
    margin: 0 15px;
  }
  
  .header--hp01-video-content ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 600;
    border: 1px solid #ffffff;
    border-radius: 45.813px;
    padding: 12px 22px;
    text-transform: lowercase;
    position: relative;
    transition: all 0.3s ease-in-out;
    text-shadow: 0px 2px 13px rgba(0, 0, 0, 1);
    text-shadow: -2px 3px 17px rgba(0, 0, 0, 1),
             0px 0px 25px rgba(7, 21, 8, 0.89);
  }
  
  .header--hp01-video-content ul li a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: #d2ab67;
  }
  
  /* Media Query for mobile view */

  @media (max-width: 320px) {
    .header--hp01-video-content ul li a {
        color: #fff;
        text-decoration: none;
        font-size: 0.25rem;
        font-weight: 200;
        border: 1px solid #ffffff;
        border-radius: 45.813px;
        padding: 6px 12px;
        text-transform: lowercase;
        position: relative;
        transition: all 0.3s ease-in-out;
      }
      .header--hp01-video-content {
        color: #fff;
        text-align: center;
        margin-top: 150px;
        margin-left: 8px;
      }
  }
  @media (max-width: 768px) {
    .header--hp01-video-content {
      color: #fff;
      text-align: center;
      margin-top: 180px;
      margin-left: 20px;
    }
  
    .header--hp01-video-desk {
      display: none;
    }
  
    .header--hp01-video-mob {
      display: block;
    }
  
    .header--hp01-video-content h1 {
      font-size: 2rem;
      line-height: 115px;
    font-weight: 400;
    position: relative;
    padding-bottom: 0px;
        margin-bottom: 20px;
        margin-top: 25px;
    font-family: "Cinzel Decorative", serif;
    font-weight: 700;
    font-style: normal;
    }
  
    .header--hp01-video-content ul li {
      margin: 0 10px;
    }
  
    .header--hp01-video-content ul li a {
      font-size: 1rem;
    }
  }
  #shadow{
    /* Adding box-shadow for inset effect */
    -webkit-box-shadow: inset 200px -74px 126px -14px rgba(0,0,0,0.72);
    -moz-box-shadow: inset 200px -74px 126px -14px rgba(0,0,0,0.72);
    box-shadow: inset 200px -74px 126px -14px rgba(0,0,0,0.72);
      }