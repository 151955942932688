* {
  font-family: "Inter", sans-serif;
}
body {
  font-family: "Inter", sans-serif;
}
.heading-main-box {
  margin: auto;
  text-align: center;
  /* padding: 20px 0px; */
  font-family: "Inter", sans-serif;
}



@font-face {
  font-family: 'Samarkan';
  src: url('./assets/fonts/Samarkan.ttf') format('truetype');
}

li::marker {
  content: '∞︎︎'; 
  /* 👩🏻‍🎓˙✧˖°🎓 ༘⋆｡   ♾️ ∞︎︎ ∞ */
      font-size: 22px; 
      color: #000; 
      /* position: absolute; */
      /* left: 0;  */
      /* top: 50%;  */
      /* transform: translateY(-50%); */
      /* padding-right: 50px; */
}

#font-color{
  background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 {
  margin: auto;
  text-align: center;
  
}

h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
p {
  font-size: 16px;
  text-align: justify;
}

/* Media query for larger screens (above 1200px) */


/* Media query for medium screens (between 768px and 1199px) */
@media screen and (min-width: 768px) and (max-width: 1199px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 28px;
  }
  h5 {
    font-size: 24px;
  }
  p {
    font-size: 18px;
  }
}

/* Media query for smaller screens (below 768px) */
@media screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
  }
}



.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  text-align: center;
  margin: auto;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-3 {
  background-image: linear-gradient(
    to right,
    rgb(255,81,0),
    rgba(115, 160, 197, 1),
    rgb(255,81,0),
    rgba(115, 160, 197, 1)
  );
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
  /* #fc17fa 0%,
  #36e1fd 29%,
  #ff13ef 67%,
  #12d7f6 */
}

.css-vexj24-MuiGrid-root {
  flex-basis: 45% !important;
}

#venue-box {
  padding-bottom: 50px;
  background-color: #000;
  color: #fff;
  border-radius: 30px;
  width: 80%;
  margin: auto;
  margin-bottom: 100px;
  padding-top: 20px;
  padding-bottom: 30px;
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
}

.heading-main-box h5 {
  font-size: 16px;
  color: #000;
  font-family: "Inter", sans-serif;
}



.heading-main-box h1 {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-main-box h3 {
  font-size: 25px;
  font-family: "Inter", sans-serif;
  
  
}

.pages-box1 {
  width: 60%;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-family: "Inter", sans-serif;
}

.button-pg {
  /* width: 200px;
  height: 200px; */
  /* width: 25%; */
  width: 60%;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 15px;
  background-color: #000;
  padding: 20px;
  color: #fff;
  font-family: "Inter", sans-serif;
}

.button-pg:hover {
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
}

.venue-item-box {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px;
  /* width: 50%; */
}

/* face section  *

/* Default styles */
.fact-container {
  background-color: #000;
  padding: 2.5rem 2rem;
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
}

.fact-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.fact-item {
  flex: 0 0 23%;
  margin-bottom: 2rem;
  text-align: center;
}

.counter {
  display: flex;
}

.counter-value {
  font-size: 2rem;
}

.text-primary {
  color: #000;
}

.text-white {
  color: #fff;
  /* font-size: 20px; */
}

/* Media queries for responsiveness */
@media screen and (max-width: 1200px) {
  .fact-item {
    flex: 0 0 30%;
  }
}

@media screen and (max-width: 992px) {
  .fact-item {
    flex: 0 0 45%;
  }

  .tagline{ 
    margin-top: 30px;
  }

}

@media screen and (max-width: 768px) {
  .fact-item {
    flex: 0 0 100%;
  }

  .heading-main-box h1 {
    font-size: 18px;
    width: 90%;
    margin: auto;
    text-align: center;
  }

  .heading-main-box h3 {
    font-size: 16px;
    width: 80%;
    margin: auto;
    text-align: center;
    
  }

  /* .heading-main-box h5 {
    font-size: 10px;
  } */

  .heading-main-box img {
    width: 60px;
    background-color: #fff;
    border-radius: 50%;
  }

  #ppl-img {
    width: 60px;
    background-color: #fff;
    border-radius: 50%;
  }

  .venue-item-box {
    margin-bottom: 30px !important;
  }

  .css-vexj24-MuiGrid-root {
    flex-basis: 100% !important;
  }

  /* start */

  .profile-wrapper-events {
    width: 100%;
    height: 150px;
    position: relative;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .profile-events {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 150px;
  }

  .button-2 {
    padding: 0.4em 1em;
    font-size: 14px;
    font: bolder;
  }
  /* end */
}

/* fact section extenction start */


.counter {
  display: flex;
  align-items: center; /* Vertically aligns h1 and h5 */
}

.counter h1 {
  font-size: 2.5rem; /* Adjust size to fit visually with h5 */
  margin-right: 15px; /* Space between h1 and h5 */
}

.counter h5 {
  font-size: 1.2rem; /* Adjust size for balance */
  margin: 0;
  line-height: 1.5; 
  text-align: justify;
}

@media (max-width: 768px) {
  .counter h1 {
    font-size: 2rem; /* Adjustments for smaller screens */
  }
  .counter h5 {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .counter h1 {
    font-size: 1.8rem;
  }
  .counter h5 {
    font-size: 0.9rem;
  }
}


/* fact section extenction end */


/* About */

.about-main-box {
  width: 80%;
}


  .announcement-container h1{
    background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.css-1d3bbye {
  width: 80% !important;
  margin: auto;
}

.css-11lq3yg-MuiGrid-root {
  width: 80% !important;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.activities-para-boxs {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  /* width: 350px; */
  height: 500px;
  border-radius: 15px;
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
  color: #fff;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.activities-para-boxs-online {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  /* width: 350px; */
  height: 100%;
  border-radius: 15px;
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
  color: #fff;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.activities-para-boxs-online img {
  width: 100%;
  border-radius: 15px;
}

.activities-para-boxs img {
  width: 200px;
  border-radius: 15px;
}

/* .activities-para-boxs:hover{
    background-color: #12bed3;
    padding: 22px;
    color: #fff;
  } */

  .tagline{
    /* background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%); */
    color: rgb(255,81,0);
    border-radius: 15px;
    padding: 10px 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 30px;
  }

  .translation {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid rgb(255,81,0);
    border-radius: 5px;
    background-color: #fff3e0;
  }

.profile-wrapper {
  width: 280px;
  height: 370px;
  position: relative;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 280px;
  height: 370px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.5s;
}

.profile .profile-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  z-index: 2;
  background-color: var(--background);
  transition: 0.5s;
}

.profile-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profile:hover img {
  opacity: 0.4;
  transition: 0.5s;
}
/* end */

/* start */

.profile-wrapper-events {
  width: 90%;
  height: 370px;
  position: relative;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.profile-events {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 90%;
  height: 370px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.5s;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.profile-events .profile-image-events {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  z-index: 2;
  background-color: var(--background);
  transition: 0.5s;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.profile-image-events img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profile-events:hover img {
  opacity: 0.4;
  transition: 0.5s;
}

.heading-main-box img {
  width: 110px;
  background-color: #fff;
  border-radius: 50%;
}

#ppl-img {
  width: 110px;
  background-color: #fff;
  border-radius: 50%;
}

/* end */

/* contact  */

.profile-wrapper-contact {
  width: 100%;
  height: 370px;
  position: relative;
}

.profile-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 80%;
  height: 370px;
  margin: auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.5s;
}

.profile-contact .profile-image-contact {
  /* position: absolute; */
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  z-index: 2;
  background-color: var(--background);
  transition: 0.5s;
}

.profile-image-contact img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profile-contact:hover img {
  opacity: 0.4;
  transition: 0.5s;
}

/* ----------button----------- */

.button-1 {
  padding: 15px;
  border-radius: 15px;
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
  color: #fff;
  border: 0px;
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
}

.button-1:hover {
  background-color: #000;
  border: 0px;
  background: rgb(0, 15, 38);
}

/* <!-- HTML !--> */
/* <button class="button-85" role="button">Button 85</button> */

.button-2 {
  padding: 0.9em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: rgb(255,81,0);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-2:before {
  content: "";
  background: linear-gradient(
    45deg,
    rgb(255,81,0),
    #ff7300,
    #fffb00,
    rgb(255,81,0),
    rgb(255,81,0),
    rgb(255,81,0),
    rgb(255,81,0),
    #ff00c8,
    rgb(255,81,0)
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-2 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-2 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}



.button-2:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #; */
  left: 0;
  top: 0;
  border-radius: 10px;
}

.about-container {
  /* max-width: 800px; */
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.about-container .heading1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-container .para1 {
  font-size: 1.8rem;
  margin-top: 30px;
  background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.highlit-points{
  background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ab-mainbox {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between boxes */
  margin: 20px; 
  margin: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ab-box1, .ab-box2 {
  flex: 1; 
  margin: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.ab-box1 img {
  width: 60%; 
  height: auto; 
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ab-box1 img:hover {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.ab-box2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



@media (max-width: 768px) {
  .ab-mainbox {
    flex-direction: column; /* Stack boxes vertically on small screens */
  }

}

@media (max-width: 480px) {

  .ab-box1 img {
    width: 80%; 
    
  }

}

.about-container .text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.about-container .list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
}

.about-container .list-item {
  margin-bottom: 10px;
}

.about-container .list-item strong {
  font-weight: bold;
}
/* about end */



/* blog start */

.container {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 100px;
}

.card {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 100%;
}

.card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 10px;
}

.tag-teal {
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}

.user {
  display: flex;
  margin-top: auto;
}

.user img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.user-info h5 {
  margin: 0;
}

.user-info small {
  color: #545d7a;
}

/* Container styling */
.three-boxes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  padding: 20px;
  width: 80%;
  margin: auto;
}

/* Box styling */
.three-boxes-box {
  flex: 1;
  min-width: 150px; /* Minimum width for each box */
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* text-align: justify; */
  background-color: #f9f9f9;

  
}


/* Media query for small screens */
@media (max-width: 320px) {
  .three-boxes-container {
    flex-direction: column;
  }

  .three-boxes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
    padding: 20px;
    width: 95%;
    margin: auto;
    /* text-align: justify; */
  }

}

@media (max-width: 768px) {
  

  .three-boxes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
    padding: 20px;
    width: 95%;
    margin: auto;
    /* text-align: justify; */
    flex-direction: column;
  }

}


/* blog end */

.testimonials {
  padding: 100px 0;
}

.testimonials h3 {
  margin-bottom: 20px;
}

.testimonial-carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.testimonial-item {
  flex: 0 0 auto;
  scroll-snap-align: start;
  width: 80%;
  max-width: 400px;
  margin-right: 20px;
  transition: transform 0.5s ease;
}

.testimonial-item:hover {
  transform: scale(1.05);
}

.testimonial-item .card {
  border-bottom: 3px #007bff solid !important;
  transition: 0.5s;
  margin-top: 60px;
}

.testimonial-item .card i {
  background-color: #007bff;
  color: #ffffff;
  width: 75px;
  height: 75px;
  line-height: 75px;
  margin: -40px auto 0 auto;
}

/* contact start  */

.contact-container {
  width: 100%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 50px;
  color: #fff;
  text-align: center;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  background: rgb(255, 154, 47);
  /* background: linear-gradient(207deg, rgba(255,154,47,1) 0%, rgba(0,0,137,1) 100%); */
}

.contact-details {
  padding-top: 10px;
  padding-bottom: 10px;
}

/* enrollment */

form {
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  width: 50%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
}

form label {
  display: block;
  margin-bottom: 10px;
}

form input[type="text"],
form input[type="date"],
form input[type="tel"],
form input[type="email"],
form select,
form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

form select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px;
}

form ul {
  list-style-type: none;
  padding-left: 0;
}

form ul li {
  margin-bottom: 10px;
}

.enroll-form-button {
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.enroll-form-button:hover {
  background-color: #00ff00;
  color: #000;
}

.enroll-upi-div {
  margin: auto;
  width: 100%;
  text-align: center;
  align-items: center;
}

.enroll-upi-img {
  width: 200px;
  height: 200px;
  margin: auto;
  text-align: center;
  align-items: center;
}

/* schedule start */



.schedule-mainbox {
  margin: auto;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #fff;
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
}

/* schedule end */

/* FaqComponent.css */
.faq-main-box {
  margin: auto;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 50px;
  margin-top: 50px;
}

.faq-heading {
  margin: 50px auto;
  text-align: center;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h5 {
  margin-top: 20px;
}

.faq-item p {
  margin-top: 10px;
}

/* FAQS end */

/* PrivacyPolicy.css */
.privacy-policy-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.privacy-policy-title {
  margin: 20px 0;
  text-align: center;
}

.privacy-policy-content {
  line-height: 1.6;
}

.privacy-policy-subtitle {
  margin-top: 20px;
}

.privacy-policy-section {
  margin-top: 30px;
}

.privacy-policy-section-title {
  margin-bottom: 10px;
}

/* privacy-policy end */

/* TermsAndConditions.css */

.terms-and-conditions-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.terms-and-conditions-title {
  font-size: 24px;
  text-align: center;
}

.terms-and-conditions-subtitle {
  font-size: 20px;
  margin-top: 20px;
}

/* TermsAndConditions end */



/* Footer start */

.footer-main-box {
  width: 100%;
  margin: auto;
  padding: 30px;
  text-decoration: none;
  
  
}

.footer-box1 img {
  width: 250px;
  /* height: 100px; */
  filter: drop-shadow(5px 5px 5px #fff);
}

.footer-box2 h2 {
  text-align: center;
  font-size: 22px;
}

.footer-box3 h2 {
  text-align: center;
  font-size: 22px;
}

/* Footer end */

/* ChatGPT */

.cs-message-list .cs-message.cs-message--outgoing,
.cs-message-list .cs-message-group.cs-message-group--outgoing {
  margin: auto !important;
}

.cs-message--outgoing .cs-message__content {
  margin-top: 5px;
  border-radius: 15px !important;
  background-color: #ffffff !important;
}

.cs-message-list {
  /* background-image: url('D:\Projects\LearnoRama\learnorama-final\learnorama\public\images\LEARNORAMA-LOGO.png');
  background-repeat: no-repeat;
  background-size: 90% 100%;
  margin: auto; */
  /* background: rgb(2,0,36);
  background: linear-gradient(352deg, rgba(2,0,36,1) 0%, rgba(9,30,121,1) 35%, rgba(0,212,255,1) 100%); */
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
}

.cs-main-container .cs-chat-container {
  /* background: rgb(2,0,36);
  background: linear-gradient(352deg, rgba(2,0,36,1) 0%, rgba(9,30,121,1) 35%, rgba(0,212,255,1) 100%); */
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
}

/* popup home start */

.offlinecamp-maingrid {
  width: 40% !important;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}

.offlinecamp-b1 {
  border-radius: 15px;
  box-shadow: rgba(199, 16, 223, 0.35) 0px 5px 15px;
  margin: 5px;
  padding: 15px;
}

.offlinecamp-b2 {
  border-radius: 15px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  margin: 5px;
  padding: 15px;
}

.fest-heading {
  color: rgb(255,81,0);
  font-size: 16px;

  font: bolder;
}

.offlinecamp-maingrid img {
  width: 300px;
  border-radius: 15px;
}

.camp-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin-top: 50px;
  margin-bottom: 50px;
  background: transparent;
  border-radius: 15px;
  /* background: url('./bgimg/cult-bg2.png'); */
}

.cult-img-div img {
  width: 35%;
  display: flex;
  margin: auto;
  border-radius: 15px;
}

.custom-popup {
  text-align: center;
}

.custom-popup .close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.custom-popup .popup-content {
  padding: 20px;
}

.custom-popup input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.custom-popup button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* popup home end */

/* {youtube videos start } */

.video-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  width: 80%;
  margin: auto;
}

.video-wrapper {
  flex: 1 1 22%; /* 4 videos per row on medium and large screens */
  max-width: 22%; /* Limit width to 22% */
  box-sizing: border-box;
}

.video-wrapper iframe {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

@media (max-width: 480px) {
  .video-wrapper {
    flex: 1 1 100%; /* 1 video per row on small screens */
    max-width: 100%;
  }

  

}

@media (max-width: 768px) {
  .video-wrapper {
    flex: 1 1 100%; /* 1 video per row on small screens */
    max-width: 80%;
  }

  .offlinecamp-maingrid {
    width: 80% !important;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
  }

  .offlinecamp-maingrid img {
    width: 250px;
    border-radius: 15px;
  }
  .cult-img-div img {
    width: 100%;
    display: flex;
    margin: auto;
    border-radius: 15px;
  }

  .btn-hover {
    width: 120px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
  }
}

/* {youtube videos end } */

/* text gene */

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}


.input-container {
  margin-bottom: 20px;
}

.input-container label {
  display: block;
  margin-bottom: 5px;
}

.input-container input[type="text"],
.input-container textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  text-align: center;
  margin-top: 10px;
}

.button-container button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.status-container {
  margin-top: 20px;
  font-weight: bold;
}

.video-container {
  margin-top: 20px;
}

.video-container video {
  width: 100%;
  border-radius: 4px;
}

.rythmic-container {
  width: 65%;
  margin: auto;
  text-align: center;
  justify-content: center;
}

.rythmic-container div {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 15px;
  width: auto;
  margin: 15px;
}

.rythmic-container div:hover {
  width: 95%;
}

.rythmic-container h1 {
  color: rgb(255,81,0);
}

.rythmic-container h3 {
  color: rgb(255,81,0);
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  form {
    background-color: #000;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    width: 85%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tagline{
    
    margin-top: 30px;
  }

  .MuiButtonBase-root{
    color: #fff;
  }

  .video-grid {
    
    width: 100%;
    margin: auto;
  }

  .button-pg {
    width: 80%;
  }

  .enroll-upi-div {
    margin: 5px; /* Example adjustment */
  }

  .enroll-upi-img {
    width: 120px; /* Example adjustment */
    height: 120px; /* Example adjustment */
  }

  .enroll-upi-h3{
    font-size: 8px;
  }

  .enroll-upi-heading{
    font-size: 14px;
  }

  label{
    font-size: 14px;
  }


  .about-container .text {
    font-size: 0.8rem;
    /* line-height: 1.6; */
    margin-bottom: 20px;
    text-align: justify;
  }

  .about-container .para1 {
    font-size: 1.2rem;
    margin-top: 30px;
    color: rgb(255,81,0);
}

.about-container .heading1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.card {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 100%;
}

.pages-box1 {
  width: 100%;
}

  .offlinecamp-maingrid {
    width: 80% !important;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
  }

  .offlinecamp-maingrid img {
    width: 150px;
    border-radius: 15px;
  }
  .cult-img-div img {
    width: 100%;
    display: flex;
    margin: auto;
    border-radius: 15px;
  }

  .btn-hover {
    width: 120px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: 0.8rem !important;
  }

  /* schedule start */

  h1 {
    margin: auto;
    text-align: center;
  }

  .schedule-mainbox {
    margin: auto;
    max-width: 800px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .faq-main-box {
    margin-bottom: 50px;
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .heading-main-box h1 {
    font-size: 18px;
    width: 90%;
    margin: auto;
    text-align: center;
  }

  .heading-main-box h3 {
    font-size: 16px;
    width: 80%;
    margin: auto;
    text-align: center;
  }

  /* .heading-main-box h5 {
    font-size: 10px;
  } */

  .heading-main-box img {
    width: 60px;
    background-color: #fff;
    border-radius: 50%;
  }

  #ppl-img {
    width: 60px;
    background-color: #fff;
    border-radius: 50%;
  }

  .venue-item-box {
    margin-bottom: 30px !important;
  }

  .css-vexj24-MuiGrid-root {
    flex-basis: 100% !important;
  }

  /* start */

  .profile {
   
    width: 170px;
    height: 250px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
    
  }

  .profile-wrapper {
    width: 170px;
    height: 250px;
    position: relative;
}

  .profile-wrapper-events {
    width: 100%;
    height: 150px;
    position: relative;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .profile-events {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 150px;
  }

  .button-2 {
    padding: 0.4em 1em;
    font-size: 14px;
  }
  /* end */
}

@media only screen and (min-width: 421px) and (max-width: 768px) {
  form {
    background-color: #000;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    width: 80%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tagline{
    margin-top: 40px;
  }

  .enroll-upi-div {
   
    margin: 5px; 
  }

  .enroll-upi-img {
    
    width: 120px; 
    height: 120px; 
  }

  .enroll-upi-h3{
    font-size: 10px;
  }

  .enroll-upi-heading{
    font-size: 16px;
  }

  label{
    font-size: 16px;
  }

  /* schedule start */

  h1 {
    margin: auto;
    text-align: center;
  }

  .schedule-mainbox {
    margin: auto;
    max-width: 800px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .heading-main-box h1 {
    font-size: 18px;
    width: 90%;
    margin: auto;
    text-align: center;
  }

  .heading-main-box h3 {
    font-size: 16px;
    width: 80%;
    margin: auto;
    text-align: center;
  }

  .heading-main-box h5 {
    font-size: 10px;
  }

  .heading-main-box img {
    width: 60px;
    background-color: #fff;
    border-radius: 50%;
  }

  #ppl-img {
    width: 60px;
    background-color: #fff;
    border-radius: 50%;
  }

  .venue-item-box {
    margin-bottom: 30px !important;
  }

  .css-vexj24-MuiGrid-root {
    flex-basis: 100% !important;
  }

  /* start */

  .profile-wrapper-events {
    width: 100%;
    height: 150px;
    position: relative;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .profile-events {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 150px;
  }

  .button-2 {
    padding: 0.4em 1em;
    font-size: 14px;
  }
  /* end */
}


/* chatbot */

.css-vkc4v8-MuiTypography-root {
  color: rgb(255,81,0) !important;
}

.css-ndaqpv-MuiTypography-root {
  color: rgb(255,81,0) !important;
}

.css-10hburv-MuiTypography-root{
  color: rgb(255,81,0) !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: rgb(255,81,0) !important;
}

.css-x2gqwx {
  background-color: rgb(255,81,0) !important;
  border-radius: 15px 0px 15px 0px !important;
}

.css-2w1242 {
  border-radius: 20px !important;
}



/* more start */

.more-main-heading h1{
  background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 20px;
}

.more-boxes-container {
  width: 100%;
  margin: auto;
  padding: 20px;
}

.more-box {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(115,160,197,1);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: rgba(115,160,197,1) 0px 5px 15px;
  transition: transform 0.3s;
  background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
  text-align: center;
  margin: auto;
  height: 100%; 
  max-height: 100%; 
  text-align: center;
  justify-content: center;
  align-items: center;
}

.more-box h1 {
  color: #fff;
  margin: 10px 0;
  font-size: 1.2rem;
  flex: 1; /* Allow h1 to grow and fill available space */
}

.more-box:hover {
  transform: scale(1.05);
}

.more-box-image {
  width: 100%;
  height: 350px; 
  object-fit: cover; /* Ensure images cover the box without distortion */
}

.more-box-title {
  margin: 10px 0;
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
  .more-boxes-container {
    /* Grid layout managed by Material-UI Grid component */
  }

  

  .more-box {
    height: 100%; 
  }

  .more-box-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

  .css-y1ks9h-MuiGrid-root {
    width: calc(90% + 16px) !important;
    margin: auto !important;
  }

}

@media (max-width: 786px) {
  .more-box {
    height: 100%; 
  }

  .more-box-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}
}



/* Small screens (mobile) */
@media (max-width: 600px) {

  .css-y1ks9h-MuiGrid-root {
    width: calc(90% + 16px) !important;
    margin: auto !important;
  }

}

@media (max-width: 320px) {
  .more-box {
    height: 100%; 
  }

  .more-box-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}
}



/* more end */

/* Gallery.css start */

.gallery-container {
  padding: 20px;
  width: 80%;
  margin: auto;
  justify-content: center;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.gallery-item {
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.1);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.lightbox-image {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
}

.lightbox-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 5px;
}

.lightbox-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #D6291E;
  color: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.lightbox-prev {
  left: 20px;
}

.lightbox-next {
  right: 20px;
}

.video-section {
  margin-top: 40px;
  padding: 20px;
}

.video-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  
}

.video-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video-item iframe {
  width: 100%;
  height: 200px;
  border: none;
}

@media (min-width: 320px) and (max-width: 480px) {
  .video-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: auto;
    text-align: center;
}

.video-grid {
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  margin: auto;
    text-align: center;
    justify-content: center;
    
}

.lightbox-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #D6291E;
  color: #ffffff;
  border-radius: 15px;
  border: none;
  padding: 7px;
  cursor: pointer;
  font-size: 6px;
}

.box-title2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #D6291E;
  margin-top: 25PX;
}

}

@media (min-width: 425px) and (max-width: 599px) {
  .video-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.video-grid {
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

}


@media (min-width: 481px) and (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .box-title2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #D6291E;
    margin-top: 25PX;
  }

  .lightbox-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #D6291E;
    color: #ffffff;
    border-radius: 15px;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
  }

  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    height: 500px;
        width: 50%;
        margin: auto;
  }

  .video-item iframe {
    height: 100%;
  }

  .lightbox-image {
    max-width: 90%;
    max-height: 60%;
    margin-top: 80px;
  }

  .lightbox-close {
    font-size: 1.5rem;
  }

  .video-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

}

/* Medium screens (small laptops) */
@media (min-width: 769px) and (max-width: 1024px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .box-title2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #D6291E;
    margin-top: 25PX;
  }

  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }

  .video-item iframe {
    height: 100%;
  }

  
  .lightbox-image {
    max-width: 85%;
    max-height: 75%;
    margin-top: 120px;
  }

  

}

/* Large screens (desktops and up) */
@media (min-width: 1025px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    height: 200px;
  }

  .video-item iframe {
    height: 100%;
  }

  .lightbox-image {
    max-width: 90%;
    max-height: 80%;
  }
}



/* gallery end */

/* ParentsTalk start */

.parentsTalk-section {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.parentsTalk-container {
  padding: 20px;
}

.parentsTalk-heading h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.parentsTalk-testimonialsSection {
  position: relative;
}

.parentsTalk-sliderPagination {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.pagination-bullet {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;

}

.pagination-bullet.active {
  background-color: #D6291E;
}

.parentsTalk-testimonials {
  display: flex;
  justify-content: center;
}

.testimonial-item {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 45px;
  border-radius: 15px;
  background-color: #fff;
  text-align: center;
  max-width: 400px;
  transition: opacity 0.5s ease-in-out;
  height: 300px;
  font-weight: 900;
  color: #fff;
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
}

.testimonial-item:hover{
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(231, 29, 54) 18.9%, rgb(241, 115, 30) 90.7%);
}

.testimonial-client {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  color: #fff;
}

.testimonial-client, .testimonial-item p{
  color: #fff;
}

.testimonial-client img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Google Reviews Button */
.google-reviews-container {
  text-align: center; /* Center align the button container */
  margin: 20px 0; /* Add margin to space out from other content */
}

.google-reviews-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background: rgb(255,81,0);
background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
}

.google-reviews-button:hover {
  background: rgb(255,81,0);
  background: linear-gradient(110.1deg, rgb(231, 29, 54) 18.9%, rgb(241, 115, 30) 90.7%);
  transform: scale(1.05);
}


/* ParentsTalk end */

/* Basic Styles */
.Home-video-grid-main-box {
  display: flex;
  justify-content: center;
  padding: 20px;
  border-radius: 15px;
}

.Home-video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  border-radius: 15px;
}

.Home-video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 15px;
}

iframe {
  width: 100%;
  height: 100%;
  max-width: 560px;
  max-height: 315px;
  border-radius: 15px;
}

/* Media Queries */
@media (max-width: 768px) {
  .Home-video-grid {
    grid-template-columns: 1fr;
    width: 80%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .Home-video-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    width: 50%;
  }
}

@media (min-width: 1025px) {
  .Home-video-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

/* website and desgining start */

.projects-container {
  width: 80%;
margin: auto;
align-items: center;
margin-top: 30px;
}

.projects-container h1 ,.projects-container h2 {
 
  background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}


.website-container {
  display: flex;
  gap: 35px;
  
  align-items: stretch; 
  margin: auto;
  align-items: center;
  justify-content: center;
  width: 75%;
  margin: auto;
  border-radius: 15px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 50px;
}

.website-left-box, 
.website-right-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: justify;

}

.website-right-box h2,.website-right-box h3, strong{
  background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.website-image {
  width: 100%;
  height: 100%; 
  object-fit: cover; 
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.website-right-box ul {
  list-style-type: none;
  padding: 0;
}

.website-right-box li {
  margin-bottom: 10px;
}

/* .website-link {
  display: inline-block;
  margin-top: 15px;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  border: 2px solid #007bff;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.website-link:hover {
  background-color: #007bff;
  color: #fff;
} */

@media (max-width: 768px) {
  .website-container {
    flex-direction: column;
  }

  .website-left-box,
  .website-right-box {
    width: 100%;
  }
}



/* website and desgining end */

/* AI Markating start */


.packages-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

/* Package box styling */
.packages-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.package-box {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.package-box:hover {
  transform: translateY(-5px);
}

.package-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  
}



/* Media queries for responsiveness */
@media (min-width: 768px) {
  .packages-grid {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .package-box {
    flex: 1 1 calc(50% - 20px);
    margin: 10px;
  }
}

@media (min-width: 1024px) {
  .package-box {
    flex: 1 1 calc(28% - 20px);
    margin: 10px;
  }
}


/* AI Marketing end  */

/* UpcomingEvent start */

/* Container styling */
.upcoming-event {
  padding: 20px;
  background-color: #f9f9f9;
}

/* Title styling */
.event-title {
  margin: 0 auto;
  text-align: center;
  color: #333;
  font-size: 2.5rem;
  font-weight: bold;
}

/* Event grid styling */
.event-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

/* Individual event card styling */
.event-card {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 300px;
  flex: 1 1 calc(33.33% - 20px); /* Adjust for three cards in a row */
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Event image styling */
.event-image {
  width: 100%;
  max-width: 150px;
  margin: 0 auto 15px;
  /* border-radius: 50%; */
}

/* Heading styling */
.event-heading {
  font-size: 1.5rem;
  color: #222;
  margin-bottom: 10px;
}

/* Organizer and venue styling */
.event-organizer,
.event-venue {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

/* Styled Link */
.styled-link {
  text-decoration: none;
}

/* Learn More button */
.learn-more-button {
  background-color: rgb(255,81,0);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.learn-more-button:hover {
  background-color: #004ba0;
}

/* Media queries */
@media (max-width: 768px) {
  .event-card {
    flex: 1 1 calc(50% - 20px); /* Two cards per row on tablets */
  }

  .event-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .event-card {
    flex: 1 1 100%; /* Single card per row on small screens */
  }

  .event-title {
    font-size: 1.8rem;
  }

  .learn-more-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}


/* UpcomingEvent end */

/* event learnmorepage start */

/* General Styles */
.LearnMorePage-container {
  padding: 20px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  width: 80%;
  margin: auto;
}

.LearnMorePage-title {
  font-size: 2.5rem;
  text-align: center;
  color: rgb(255,81,0);
  margin-bottom: 10px;
  text-transform: uppercase;

}

.LearnMorePage-subtitle {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
  color: #555;
}

.LearnMorePage-section {
  margin-bottom: 30px;
}

.LearnMorePage-section-title {
  font-size: 1.8rem;
  color: rgb(255,81,0);
  margin-bottom: 10px;
}

.LearnMorePage-section-content {
  font-size: 1rem;
  margin-bottom: 10px;
}

.LearnMorePage-activities-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.LearnMorePage-activity-card {
  background: #fff;
  border-radius: 15px;
  padding: 55px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(50% - 20px); /* Two cards per row */
}

.LearnMorePage-activity-card h2 {
  font-size: 1.3rem;
  color: rgb(255,81,0);
  margin-bottom: 10px;
}

.LearnMorePage-activity-card ul {
  list-style: none;
  padding: 0;
}

.LearnMorePage-activity-card li {
  margin-bottom: 8px;
}

.LearnMorePage-special-events {
  list-style-type: disc;
  margin-left: 20px;
}

.LearnMorePage-special-events li {
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {

  .LearnMorePage-container {
    width: 90%;
  }

  .LearnMorePage-activities-grid {
    flex-direction: column;
  }

  .LearnMorePage-activity-card {
    flex: 1 1 100%;
  }

  .LearnMorePage-title {
    font-size: 2rem;
  }

  .LearnMorePage-section-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {

  .LearnMorePage-container {
    width: 95%;
  }


  .LearnMorePage-title {
    font-size: 1.8rem;
  }

  .LearnMorePage-section-title {
    font-size: 1.3rem;
  }

  .LearnMorePage-section-content {
    font-size: 0.9rem;
  }
}


/* event learnmorepage end */
