.meal-plan-container {
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
  }
  
  .age-group-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .meal-day {
    margin-bottom: 40px;
  }
  
  .day-title {
    font-size: 1.25rem;
    color: #555;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  
  .meal-type {
    margin-bottom: 20px;
  }
  
  .meal-type-title {
    font-size: 1.125rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
  }
  
  .meal-list {
    list-style-type: none;
    padding: 0;
  }
  
  .meal-item {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #666;
  }
  
  .meal-item strong {
    color: #333;
  }
  
  .meal-item em {
    color: #888;
  }
  
/* ------------------------------------------------- */
  
/* File: AgeGroups.css */
.page-title {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
  }
  
  .age-groups-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 80%;
    margin: 0 auto; /* Center the container horizontally */
  }
  
  .age-group-box {
    flex: 1;
    min-width: 200px; 
    padding: 100px 90px;
    background: rgb(255,81,0);
    background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .age-group-box:hover {
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .age-groups-container {
      flex-direction: column;
    }
  }
  