/* Registration.css */
.registration-form {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.registration-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.registration-form div {
  margin-bottom: 15px;
}

.registration-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.registration-form input {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.registration-form button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #212b88;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.registration-form button:hover {
  background-color: #212b88;
}

.registration-form button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
