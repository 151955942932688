/* General Styling */
body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    color: #333;
    margin: 0;
    padding: 0;
    overflow: auto;
  }
  
  /* V-C-container for Chapters and Videos */
  .V-C-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    overflow: auto;

    background: linear-gradient(
    319deg,
    rgba(0, 15, 38, 1) 0%,
    rgba(115, 160, 197, 1) 96%,
    rgba(152, 221, 237, 1) 100%
  );
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 15px;
  color: #fff;

  }
  
  /* Heading Styles */
  h1, h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* List Styling */
  .V-C-container ul {
    list-style: none;
    padding: 0;
  }
  
  /* Chapter and Video Items Layout */
  .chapter-list-item {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 15px;
    /* display: flex; */
    flex-direction: column;
    transition: box-shadow 0.3s;
    margin: auto;
    text-align: center;
    justify-content: center;
    margin: 10px;
  }


  .video-list-item {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.3s;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  .chapter-list-item:hover,
  .video-list-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .chapter-title {
    font-size: 1.2em;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .chapter-details,
  .video-details {
    color: #555;
    font-size: 18px;
  }
  
  .video-thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .video-details {
    flex: 1;
  }
  
  /* Button Styling */
  /* button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: start;
  }
  
  button:hover {
    background-color: #0056b3;
  } */
  
  /* Loading and Error Messages */
  p.loading,
  div.error {
    text-align: center;
    color: #ff0000;
    font-size: 1.2em;
    /* margin: 20px 20px; */
    border: 1px solid red;
    border-radius: 20px;
    width: 80%;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 10px;
  }
  
  p.loading {
    color: #007bff;
  }

  .error{
    display: flex;
    flex-direction: column;
  }

  .button-payment{
    width: 40%;
    margin: auto;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #fd7e14;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .button-payment:hover{
    background-color: #48ff00;
    
  }

  .button-2 {
    padding: 1.1em 1.2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #fd7e14;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-2:before {
    content: "";
    background: linear-gradient(
      45deg,
      #fd7e14,
      #ff7300,
      #f2f1b4,
      #fd7e14,
      #fd7e14,
      #fd7e14,
      #fd7e14,
      /* #ede0c5, */
      #fd7e14
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-2 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  @keyframes glowing-button-2 {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .button-2:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: #; */
    left: 0;
    top: 0;
    border-radius: 10px;
  }
  
  /* Media Queries */
  
  /* For larger screens, display items side by side */
  @media (min-width: 768px) {
    .V-C-container ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .chapter-list-item {
      flex: 0 0 20%;
      margin-bottom: 20px;
      flex-direction: row;
      align-items: center;
    }

   
    .video-list-item {
      flex: 0 0 48%;
      margin-bottom: 20px;
      flex-direction: row;
      align-items: center;
      
    }
  
    .video-thumbnail {
      margin-bottom: 0;
      margin-right: 15px;
    }
  
    .video-details {
      display: flex;
      flex-direction: column;
    }
  }
  
  /* For smaller screens, stack items vertically */
  @media (max-width: 767px) {
    .chapter-list-item,
    .video-list-item {
      flex: 0 0 100%;
      flex-direction: column;
    }
  }
  