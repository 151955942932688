/* Basic Styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    color: #333;
    margin: 0;
    padding: 0;
    overflow: auto;
  }
  
  .lesson-container {
    /* max-width: 1200px; */
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    gap: 5px;
    background: linear-gradient(
    319deg,
    rgba(0, 15, 38, 1) 0%,
    rgba(115, 160, 197, 1) 96%,
    rgba(152, 221, 237, 1) 100%
  );
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 15px;
  color: #fff;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    /* color: #fff; */
    
  }
  
  /* Layout for Large Screens */
  .lesson-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    gap: 15px;
    
    
  }
  
  .lesson-details img {
    flex: 0 0 40%;
    /* max-width: 40%; */
    width: 30%;
    margin-right: 20px;
    padding: 20px;
    border-radius: 30px;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #000;
  }
  
  .lesson-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: auto;
    /* text-align: center; */
    /* justify-content: center;
    align-items: center; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 20px;
    background-color: #fff;
    color: #000;
  }
  
  .lesson-info p {
    margin: 10px 0;
    /* margin-left: 50px; */
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .lesson-info h1{
    font-size: 18px;
  }
  
  /* Video Styling */
  .lesson-details video {
    width: 80%;
    max-width: 600px;
    margin-top: 20px;
    /* padding: 15px; */
    border-radius: 30px;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .lesson-details {
      flex-direction: column;
      align-items: center;
    }
  
    .lesson-details img {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    }
  
    .lesson-info {
      width: 100%;
      text-align: center;
    }
  
    .lesson-details video {
      width: 80%;
      border-radius: 30px;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    /* h1 {
      font-size: 1.5rem;
    } */

    .lesson-info h1 {
      font-size: 16px;
  }
  
    .lesson-info p {
      font-size: 0.7rem;
      text-align: justify;
    }
  
    .lesson-details video {
      width: 100%;
    }
  }
  